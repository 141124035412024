package dev.moetz.fakeitandmakeit.model

import com.benasher44.uuid.Uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class Diary(
    @Serializable(with = UUIDAsStringSerializer::class)
    val id: Uuid,
    val title: String,
    val text: String,
    val images: List<Image>,
    val locationName: String?,
    val dateTime: Instant,
) {

    @Serializable
    data class Image(
        @Serializable(with = UUIDAsStringSerializer::class)
        val id: Uuid,
        val url: String,
    )

}
