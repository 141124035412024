package dev.moetz.fakeitandmakeit.page

import dev.moetz.fakeitandmakeit.design.applyFooter
import dev.moetz.fakeitandmakeit.design.applyHeader
import dev.moetz.fakeitandmakeit.design.container
import dev.moetz.fakeitandmakeit.design.row
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import web.cssom.ClassName

external interface NotFoundPageServiceProps : Props {
}

val NotFoundPageService = FC<NotFoundPageServiceProps> { props ->
    applyHeader()

    container {
        row {
            div {
                css(ClassName("col s12")) {

                }
                p {
                    +"Page not found"
                }
            }
        }
    }

    applyFooter()
}