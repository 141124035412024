package dev.moetz.fakeitandmakeit

import dev.moetz.fakeitandmakeit.page.HomePageService
import dev.moetz.fakeitandmakeit.page.HomePageServiceManager
import dev.moetz.fakeitandmakeit.page.NotFoundPageService
import kotlinx.browser.window
import react.create
import react.dom.client.createRoot
import web.dom.document

fun main() {
    val container = document.createElement("div")
    document.body.appendChild(container)

    val path = window.location.pathname

    val element = when {
        path.isBlank() || path == "/" -> {
            HomePageService.create {
                manager = HomePageServiceManager()
            }
        }

        else -> {
            NotFoundPageService.create {

            }
        }
    }

    createRoot(container).render(element)
}