package dev.moetz.fakeitandmakeit.design

import emotion.react.css
import kotlinx.browser.window
import react.ChildrenBuilder
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.nav
import react.dom.html.ReactHTML.span
import web.cssom.ClassName
import web.cssom.Color
import web.window.WindowTarget


fun showSuccessToast(message: String) {
    showToast(
        html = message,
        classes = "green-text"
    )
}

fun showErrorToast(message: String) {
    showToast(
        html = message,
        classes = "red-text"
    )
}

fun showToast(html: String, classes: String) {
    val obj = Any().asDynamic()
    obj["html"] = html
    obj["classes"] = classes
    window.asDynamic().M.toast(obj)
}

fun ChildrenBuilder.loadingCircle() {
    br()
    br()
    div {
        className = ClassName("preloader-wrapper big active")
        div {
            className = ClassName("spinner-layer spinner-blue-only")
            div {
                className = ClassName("circle-clipper left")
                div {
                    className = ClassName("circle")
                }
            }
            div {
                className = ClassName("gap-patch")
                div {
                    className = ClassName("circle")
                }
            }
            div {
                className = ClassName("circle-clipper right")
                div {
                    className = ClassName("circle")
                }
            }
        }
    }
    br()
    br()
}

fun ChildrenBuilder.applyFooter() {
    ReactHTML.footer {
        className = ClassName("page-footer blue")
//        ReactHTML.div {
//            className = ClassName("container")
//            ReactHTML.div {
//                className = ClassName("row")
//                ReactHTML.div {
//                    className = ClassName("col l6 s12")
//                    ReactHTML.h5 {
//                        className = ClassName("white-text")
//                        +"Company Bio"
//                    }
//                    ReactHTML.p {
//                        className = ClassName("grey-text text-lighten-4")
//                        +"Some text about the service here."
//                    }
//                }
//            }
//        }

        div {
            className = ClassName("footer-copyright")
            container {
                row {
                    div {
                        className = ClassName("col l6 s6")

                        a {
                            className = ClassName("blue-text text-lighten-3")
                            href = "/docs"
                            target = WindowTarget._blank
                            +"API documentation"
                        }

//                        span {
//                            className = ClassName("red-text")
//                            +"❤"
//                        }

                    }


                    div {
                        className = ClassName("col l6 s6 right-align")

//                        a {
//                            className = ClassName("blue-text text-lighten-3")
//                            href = "/"
//                            +"Travel diary"
//                        }
//                        +" is "
//
//                        a {
//                            className = ClassName("blue-text text-lighten-3")
//                            href = "https://gitlab.moetz.dev/twitch/travel-diary"
//                            target = WindowTarget._blank
//                            +"closed source here"
//                        }
                    }
                }
            }
        }
    }
}

data class Tab(
    val label: String,
    val active: Boolean,
    val href: String? = null,
    val onClick: (() -> Unit)? = null,
)

fun ChildrenBuilder.applyHeader(
    title: String = "Travel Diary",
    tabs: List<Tab> = emptyList(),
    fixed: Boolean = false,
    connected: Boolean = true,
) {
    val content: ChildrenBuilder.() -> Unit = {
        nav {
            className = ClassName("blue darken-1 nav-extended")

            div {
                className = ClassName("container")

                div {
                    className = ClassName("nav-wrapper")
                    a {
                        id = "logo-container"
                        href = "/"
                        className = ClassName("brand-logo")
                        span {
                            css {
                                if (connected.not()) {
                                    color = Color("red")
                                }
                            }
                            +title
                        }
                    }
                }

                if (tabs.isNotEmpty()) {
                    div {
                        className = ClassName("nav-content")

                        ReactHTML.ul {
                            className = ClassName("tabs tabs-transparent")

                            tabs.forEach { tab ->
                                ReactHTML.li {
                                    className = ClassName("tab")

                                    a {
                                        if (tab.active) {
                                            className = ClassName("active")
                                        }
                                        href = tab.href
                                        +tab.label
                                        if (tab.onClick != null) {
                                            onClick = {
                                                tab.onClick.invoke()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (fixed) {
        div {
            className = ClassName("navbar-fixed")
            content.invoke(this)
        }
    } else {
        content.invoke(this)
    }
}

fun ChildrenBuilder.container(additionalClasses: String? = null, block: ChildrenBuilder.() -> Unit) {
    div {
        className = if (additionalClasses != null) {
            ClassName("container $additionalClasses")
        } else {
            ClassName("container")
        }

        block.invoke(this)
    }
}

fun ChildrenBuilder.row(additionalClasses: String? = null, block: ChildrenBuilder.() -> Unit) {
    div {
        className = if (additionalClasses != null) {
            ClassName("row $additionalClasses")
        } else {
            ClassName("row")
        }

        block.invoke(this)
    }
}

fun ChildrenBuilder.card(
    cardTitle: String,
    cardContentArea: ChildrenBuilder.() -> Unit,
    cardActionArea: ChildrenBuilder.() -> Unit
) {
    div {
        className = ClassName("card blue-grey darken-1")

        div {
            className = ClassName("card-content white-text")

            span {
                className = ClassName("card-title")
                +cardTitle
            }
//            p {
            cardContentArea.invoke(this)
//            }
        }

        div {
            className = ClassName("card-action")

            cardActionArea.invoke(this)
        }
    }
}
