package dev.moetz.fakeitandmakeit.page

import dev.moetz.fakeitandmakeit.design.applyFooter
import dev.moetz.fakeitandmakeit.design.applyHeader
import dev.moetz.fakeitandmakeit.design.container
import dev.moetz.fakeitandmakeit.design.row
import dev.moetz.fakeitandmakeit.model.Diary
import dev.moetz.fakeitandmakeit.util.ClientServiceManager
import dev.moetz.fakeitandmakeit.util.useStateFlow
import kotlinx.serialization.builtins.ListSerializer
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.p
import web.cssom.ClassName

external interface HomePageServiceProps : Props {
    var manager: HomePageServiceManager
}

class HomePageServiceManager : ClientServiceManager<List<Diary>>(
    websocketPath = "/api/v1/ws/diary",
    initialState = emptyList(),
    stateSerializer = ListSerializer(Diary.serializer()),
    localStorageConfig = null,
) {

}

val HomePageService = FC<HomePageServiceProps> { props ->
    val connected = useStateFlow(props.manager.connectedStateFlow)
    val diaries = useStateFlow(props.manager.stateAsStateFlow)

    applyHeader(
        connected = connected,
    )

    container {
        row {
            div {
                className = ClassName("col s12")
                p {
                    +"Public travel diary entries:"
                }
            }
        }
        row {
            if (diaries.isEmpty()) {
                i { +"No public diary entries yet." }
            } else {
                diaries.forEach { diary ->
                    div {
                        className = ClassName("col s12")
                        p {
                            +diary.title
                        }
                        p {
                            +diary.text
                        }
                    }
                }
            }
        }
    }

    applyFooter()
}
