package dev.moetz.reconnectingwebsocket

import io.ktor.client.*
import io.ktor.client.plugins.websocket.*
import io.ktor.websocket.*
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.*
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.seconds

class ReconnectingWebSocketClient(
    private val url: String,
    private val retryDelay: Duration = 5_000.milliseconds,
    private val scope: CoroutineScope = GlobalScope,
    private val debugOutput: Boolean = false,
    private val startToRetryInstantly: Boolean = false
) {

    private val client by lazy {
        HttpClient {
            install(WebSockets) {
                // Configure WebSockets
                pingInterval = 15.seconds
            }
        }
    }

    private val connectedStateFlow: MutableStateFlow<Boolean> = MutableStateFlow(false)
    val connected: StateFlow<Boolean> get() = connectedStateFlow.asStateFlow()

    private val receivedValuesMutableFlow: MutableSharedFlow<String> = MutableSharedFlow()
    val received: SharedFlow<String> get() = receivedValuesMutableFlow.asSharedFlow()


    private var hasConnectBeenCalled = false

    private var job: Job? = null
    private var clientWebSocketSession: DefaultClientWebSocketSession? = null

    suspend fun connect() {
        if (hasConnectBeenCalled.not()) {
            hasConnectBeenCalled = true
            connectedStateFlow.emit(false)
            job = scope.launch {
                var hasInstantlyRetried = false
                while (this.isActive) {
                    try {
                        client.webSocket(url) {
                            clientWebSocketSession = this
                            connectedStateFlow.emit(true)
                            hasInstantlyRetried = false
                            while (this.isActive) {
                                val incomingMessage = incoming.receive() as? Frame.Text
                                val text = incomingMessage?.readText()
                                if (text != null) {
                                    receivedValuesMutableFlow.emit(text)
                                }
                            }
                        }
                    } catch (throwable: Throwable) {
                        if (debugOutput) {
                            throwable.printStackTrace()
                            println("exception: $throwable")
                        }
                    } finally {
                        clientWebSocketSession = null
                    }
                    connectedStateFlow.emit(false)
                    if (debugOutput) {
                        println("websocket failed, retrying in $retryDelay")
                    }
                    if ((startToRetryInstantly && hasInstantlyRetried.not()).not()) {
                        delay(retryDelay)
                    }
                    hasInstantlyRetried = true
                    if (debugOutput) {
                        println("retrying now")
                    }
                }
            }
        }
    }

    suspend fun close() {
        clientWebSocketSession?.close(CloseReason(code = CloseReason.Codes.NORMAL, message = "close() called"))
        job?.cancel()
        hasConnectBeenCalled = false
        connectedStateFlow.emit(false)
    }

    suspend fun send(message: String) {
        if (debugOutput) {
            println("send($message)")
        }
        clientWebSocketSession?.send(Frame.Text(message))
    }

}